import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Collapse } from "react-bootstrap";
import { Trans } from "react-i18next";
import withRouter from "../../with_router";
import { useSelector } from "react-redux";
const Sidebar = (props) => {
  const { user: currentUser } = useSelector((state) => state.authReducer);

  const [state, setState] = useState({});

  function toggleMenuState(menuState) {
    if (state[menuState]) {
      setState({ [menuState]: false });
    } else if (Object.keys(state).length === 0) {
      setState({ [menuState]: true });
    } else {
      Object.keys(state).forEach((i) => {
        setState({ [i]: false });
      });
      setState({ [menuState]: true });
    }
  }

  function isPathActive(path) {
    return props.location.pathname.startsWith(path);
  }

  useEffect(() => {
    onRouteChanged();

    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
  }, [props.location]);

  function onRouteChanged() {
    document.querySelector("#sidebar").classList.remove("active");
    Object.keys(state).forEach((i) => {
      setState({ [i]: false });
    });

    const dropdownPaths = [
      { path: "/apps", state: "appsMenuOpen" },
      { path: "/basic-ui", state: "basicUiMenuOpen" },
      { path: "/advanced-ui", state: "advancedUiMenuOpen" },
      { path: "/form-elements", state: "formElementsMenuOpen" },
      { path: "/tables", state: "tablesMenuOpen" },
      { path: "/maps", state: "mapsMenuOpen" },
      { path: "/icons", state: "iconsMenuOpen" },
      { path: "/charts", state: "chartsMenuOpen" },
      { path: "/user-pages", state: "userPagesMenuOpen" },
      { path: "/error-pages", state: "errorPagesMenuOpen" },
      { path: "/general-pages", state: "generalPagesMenuOpen" },
      { path: "/ecommerce", state: "ecommercePagesMenuOpen" },
      { path: "/restaurants", state: "restaurantsPagesMenuOpen" },
      { path: "/categories", state: "categoiresPagesMenuOpen" },
      { path: "/news", state: "newsPagesMenuOpen" },
    ];

    dropdownPaths.forEach((obj) => {
      if (isPathActive(obj.path)) {
        setState({ [obj.state]: true });
      }
    });
  }

  return (
    <nav className="sidebar sidebar-offcanvas" id="sidebar">
      <ul className="nav">
        <li className="nav-item nav-profile">
          <a
            href="!#"
            className="nav-link"
            onClick={(evt) => evt.preventDefault()}
          >
            <div className="nav-profile-image">
              <img
                src={require("../../assets/images/faces/face1.jpg")}
                alt="profile"
              />
              <span className="login-status online"></span>{" "}
              {/* change to offline or busy as needed */}
            </div>
            <div className="nav-profile-text">
              <span className="font-weight-bold mb-2">
                <Trans>{currentUser?.name}</Trans>
              </span>
              <span className="text-secondary text-small">
                <Trans>ADMIN</Trans>
              </span>
            </div>
            <i className="mdi mdi-bookmark-check text-success nav-profile-badge"></i>
          </a>
        </li>
        <li
          className={
            isPathActive("/dashboard") ? "nav-item active" : "nav-item"
          }
        >
          <Link className="nav-link" to="/dashboard">
            <span className="menu-title">
              <Trans>Dashboard</Trans>
            </span>
            <i className="mdi mdi-home menu-icon"></i>
          </Link>
        </li>{" "}
        <li className={isPathActive("/about") ? "nav-item active" : "nav-item"}>
          <Link className="nav-link" to="/about">
            <span className="menu-title">
              <Trans> About Us</Trans>
            </span>
            <i className="mdi mdi-file-document-box menu-icon"></i>
          </Link>
        </li>{" "}
        <li
          className={isPathActive("/contact") ? "nav-item active" : "nav-item"}
        >
          <Link className="nav-link" to="/contact">
            <span className="menu-title">
              <Trans> Contact Info</Trans>
            </span>
            <i className="mdi mdi-phone menu-icon"></i>
          </Link>
        </li>{" "}
        <li
          className={isPathActive("/partners") ? "nav-item active" : "nav-item"}
        >
          <div
            className={
              state.partnersPagesMenuOpen
                ? "nav-link menu-expanded"
                : "nav-link"
            }
            onClick={() => toggleMenuState("partnersPagesMenuOpen")}
            data-toggle="collapse"
          >
            <span className="menu-title">
              <Trans> Partners</Trans>
            </span>
            <i className="menu-arrow"></i>
            <i className="mdi mdi-food-fork-drink menu-icon"></i>
          </div>
          <Collapse in={state.partnersPagesMenuOpen}>
            <ul className="nav flex-column sub-menu">
              <li className="nav-item">
                {" "}
                <Link
                  className={
                    isPathActive("/partners/all")
                      ? "nav-link active"
                      : "nav-link"
                  }
                  to="/partners/all"
                >
                  <Trans>All partners</Trans>
                </Link>
              </li>
              <li className="nav-item">
                {" "}
                <Link
                  className={
                    isPathActive("/news/add") ? "nav-link active" : "nav-link"
                  }
                  to="/partners/add"
                >
                  <Trans>Add partners</Trans>
                </Link>
              </li>
            </ul>
          </Collapse>
        </li>
        <li className={isPathActive("/news") ? "nav-item active" : "nav-item"}>
          <div
            className={
              state.basicUiMenuOpen ? "nav-link menu-expanded" : "nav-link"
            }
            onClick={() => toggleMenuState("newsPagesMenuOpen")}
            data-toggle="collapse"
          >
            <span className="menu-title">
              <Trans> News</Trans>
            </span>
            <i className="menu-arrow"></i>
            <i className="mdi mdi-food-fork-drink menu-icon"></i>
          </div>
          <Collapse in={state.newsPagesMenuOpen}>
            <ul className="nav flex-column sub-menu">
              <li className="nav-item">
                {" "}
                <Link
                  className={
                    isPathActive("/news/all") ? "nav-link active" : "nav-link"
                  }
                  to="/news/all"
                >
                  <Trans>All News</Trans>
                </Link>
              </li>
              <li className="nav-item">
                {" "}
                <Link
                  className={
                    isPathActive("/news/add") ? "nav-link active" : "nav-link"
                  }
                  to="/news/add"
                >
                  <Trans>Add News</Trans>
                </Link>
              </li>
            </ul>
          </Collapse>
        </li>
        <li
          className={
            isPathActive("/categories") ? "nav-item active" : "nav-item"
          }
        >
          <div
            className={
              state.basicUiMenuOpen ? "nav-link menu-expanded" : "nav-link"
            }
            onClick={() => toggleMenuState("categoiresPagesMenuOpen")}
            data-toggle="collapse"
          >
            <span className="menu-title">
              <Trans> Categories</Trans>
            </span>
            <i className="menu-arrow"></i>
            <i className="mdi mdi-food-fork-drink menu-icon"></i>
          </div>
          <Collapse in={state.categoiresPagesMenuOpen}>
            <ul className="nav flex-column sub-menu">
              <li className="nav-item">
                {" "}
                <Link
                  className={
                    isPathActive("/categories/all")
                      ? "nav-link active"
                      : "nav-link"
                  }
                  to="/categories/all"
                >
                  <Trans>All Categories</Trans>
                </Link>
              </li>
              <li className="nav-item">
                {" "}
                <Link
                  className={
                    isPathActive("/categories/add")
                      ? "nav-link active"
                      : "nav-link"
                  }
                  to="/categories/add"
                >
                  <Trans>Add Category</Trans>
                </Link>
              </li>
            </ul>
          </Collapse>
        </li>
        <li
          className={isPathActive("/products") ? "nav-item active" : "nav-item"}
        >
          <div
            className={
              state.basicUiMenuOpen ? "nav-link menu-expanded" : "nav-link"
            }
            onClick={() => toggleMenuState("productsPagesMenuOpen")}
            data-toggle="collapse"
          >
            <span className="menu-title">
              <Trans> Products</Trans>
            </span>
            <i className="menu-arrow"></i>
            <i className="mdi mdi-food-fork-drink menu-icon"></i>
          </div>
          <Collapse in={state.productsPagesMenuOpen}>
            <ul className="nav flex-column sub-menu">
              <li className="nav-item">
                {" "}
                <Link
                  className={
                    isPathActive("/products/all")
                      ? "nav-link active"
                      : "nav-link"
                  }
                  to="/products/all"
                >
                  <Trans>All Product</Trans>
                </Link>
              </li>
              <li className="nav-item">
                {" "}
                <Link
                  className={
                    isPathActive("/products/add")
                      ? "nav-link active"
                      : "nav-link"
                  }
                  to="/products/add"
                >
                  <Trans>Add Product</Trans>
                </Link>
              </li>
              {/* <li className="nav-item">
                {" "}
                <Link
                  className={
                    isPathActive("/categories/add")
                      ? "nav-link active"
                      : "nav-link"
                  }
                  to="/categories/add"
                >
                  <Trans>Add Category</Trans>
                </Link>
              </li> */}
            </ul>
          </Collapse>
        </li>
        <li
          className={isPathActive("/slider") ? "nav-item active" : "nav-item"}
        >
          <div
            className={
              state.basicUiMenuOpen ? "nav-link menu-expanded" : "nav-link"
            }
            onClick={() => toggleMenuState("slidersPagesMenuOpen")}
            data-toggle="collapse"
          >
            <span className="menu-title">
              <Trans> Slider</Trans>
            </span>
            <i className="menu-arrow"></i>
            <i className="mdi mdi-image menu-icon"></i>
          </div>
          <Collapse in={state.slidersPagesMenuOpen}>
            <ul className="nav flex-column sub-menu">
              <li className="nav-item">
                {" "}
                <Link
                  className={
                    isPathActive("/slider/all") ? "nav-link active" : "nav-link"
                  }
                  to="/slider/all"
                >
                  <Trans>All Slides</Trans>
                </Link>
              </li>
              <li className="nav-item">
                {" "}
                <Link
                  className={
                    isPathActive("/slider/add") ? "nav-link active" : "nav-link"
                  }
                  to="/slider/add"
                >
                  <Trans>Add Slide</Trans>
                </Link>
              </li>
              {/* <li className="nav-item">
                {" "}
                <Link
                  className={
                    isPathActive("/categories/add")
                      ? "nav-link active"
                      : "nav-link"
                  }
                  to="/categories/add"
                >
                  <Trans>Add Category</Trans>
                </Link>
              </li> */}
            </ul>
          </Collapse>
        </li>
      </ul>
    </nav>
  );
};

export default withRouter(Sidebar);
