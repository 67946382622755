import axios from "axios";
import { appUrl } from "../utils/constants";
const instance = axios.create({
  baseURL: appUrl, // process.env.REACT_APP_BACKEND_URL,
  headers: {
    // "Content-Type": "application/json",
  },
});

export default instance;
