import React, { useEffect, useState } from 'react';
import './App.scss';
import AppRoutes from './AppRoutes';
import Navbar from './shared/Navbar';
import Sidebar from './shared/Sidebar';
import SettingsPanel from './shared/SettingsPanel';
import Footer from './shared/Footer';

import withRouter from '../with_router';
import { useSelector } from 'react-redux';
import Login from './user-pages/Login';
import './App.css';
const App = (props) => {
  const { isLoggedIn } = useSelector((state) => state.authReducer);
  const { user: currentUser } = useSelector((state) => state.authReducer);

  const [state, setState] = useState({});

  useEffect(() => {
    onRouteChanged();
  }, [props.location]);

  let navbarComponent = !state.isFullPageLayout ? <Navbar /> : '';
  let sidebarComponent = !state.isFullPageLayout ? <Sidebar /> : '';
  let SettingsPanelComponent = !state.isFullPageLayout ? <SettingsPanel /> : '';
  let footerComponent = !state.isFullPageLayout ? <Footer /> : '';

  // function componentDidUpdate(prevProps) {
  //   if (props.location !== prevProps.location) {
  //     this.onRouteChanged();
  //   }
  // }

  function onRouteChanged() {
    console.log('ROUTE CHANGED');
    const { i18n } = props;
    console.log(props);
    const body = document.querySelector('body');
    if (props.location.pathname === '/layout/RtlLayout') {
      body?.classList.add('rtl');
      // i18n.changeLanguage("ar");
    } else {
      body?.classList.remove('rtl');
      // i18n.changeLanguage("en");
    }
    window.scrollTo(0, 0);
    const fullPageLayoutRoutes = [
      '/user-pages/login-1',
      '/user-pages/register-1',
      '/user-pages/lockscreen',
      '/error-pages/error-404',
      '/error-pages/error-500',
      '/general-pages/landing-page',
    ];
    for (let i = 0; i < fullPageLayoutRoutes.length; i++) {
      if (props.location?.pathname === fullPageLayoutRoutes[i]) {
        setState({
          isFullPageLayout: true,
        });
        document
          .querySelector('.page-body-wrapper')
          ?.classList.add('full-page-wrapper');
        break;
      } else {
        setState({
          isFullPageLayout: false,
        });
        document
          .querySelector('.page-body-wrapper')
          ?.classList.remove('full-page-wrapper');
      }
    }
  }

  if (!isLoggedIn && !currentUser) {
    return (
      <Login />
      // <div className="container-scroller">
      //    <div className="container-fluid page-body-wrapper">
      //       <div className="main-panel">
      //          <div className="content-wrapper">

      //          </div>
      //       </div>
      //    </div>
      // </div>
    );
  }
  return (
    <div className="container-scroller">
      {navbarComponent}
      <div className="container-fluid page-body-wrapper">
        {sidebarComponent}
        <div className="main-panel">
          <div className="content-wrapper">
            <AppRoutes />
            {SettingsPanelComponent}
          </div>
          {footerComponent}
        </div>
      </div>
    </div>
  );
};

export default withRouter(App);
