import axiosInstance from "./api";
import TokenService from "./token.service";
import { logout, refreshToken } from "../redux/auth/actions";
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  console.log("running process queue");
  failedQueue.forEach((prom) => {
    if (error) {
      console.log("running process queue IS ERROR");

      prom.reject(error);
    } else {
      console.log("running process queue IS NOT ERROR");

      prom.resolve(token);
    }
  });

  failedQueue = [];
};
const setup = (store) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = TokenService.getLocalAccessToken();
      if (token) {
        config.headers["Authorization"] = token; // for Spring Boot back-end
        // config.headers["x-access-token"] = token; // for Node.js Express back-end
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const { dispatch } = store;
  axiosInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      console.log("runs ..... ");
      console.log(err.config);
      const originalConfig = err.config;
      const theToken = TokenService.getLocalAccessToken();

      if (originalConfig.url !== "admins/auth/login" && err.response) {
        // Access Token was expired

        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;

          console.log("Retrying..." + originalConfig.url);

          try {
            if (isRefreshing) {
              console.log("Add to queue" + originalConfig.url);
              return new Promise(function (resolve, reject) {
                failedQueue.push({ resolve, reject });
              })
                .then((token) => {
                  console.log("proccessing  " + originalConfig.url);
                  originalConfig.headers["Authorization"] = token;
                  return axiosInstance(originalConfig);
                })
                .catch((err) => {
                  return Promise.reject(err);
                });
            }
            isRefreshing = true;

            const rs = await axiosInstance.post("/v1/admins/auth/refresh", {
              refresh_token: TokenService.getLocalRefreshToken(),
            });
            console.log(rs);
            if (rs.status !== 200) {
              throw Error();
            } else {
              const { access_token, refresh_token } = rs.data;

              processQueue(null, access_token);

              dispatch(refreshToken(access_token));
              TokenService.updateLocalAccessToken(access_token);
              TokenService.updateLocalRefreshToken(refresh_token);
              isRefreshing = false;
              return axiosInstance(originalConfig);
            }
          } catch (_error) {
            console.log(_error);
            dispatch(logout());
            return Promise.reject(_error);
          } finally {
          }
        }
      }
      isRefreshing = false;
      return Promise.reject(err);
    }
  );
};
function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export default setup;
