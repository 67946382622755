import React, { Component, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { login } from "../redux/auth/actions";

const Login = () => {
  const usernameRef = useRef();
  const passwordRef = useRef();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const loginAdmin = () => {
    setIsLoading(true);
    setError(undefined);
    const username = usernameRef.current.value;
    const password = passwordRef.current.value;

    dispatch(login(username, password))
      .then((data) => {})
      .catch((err) => {
        setIsLoading(false);
        setError(err);
        console.log(err);
      });
  };

  return (
    <div className="login-bg">
      <div className="d-flex align-items-center auth px-0 my-auto ">
        <div className="row w-100 mx-0 my-auto">
          <div className="col-lg-4 mx-auto">
            <div className="auth-form-light text-left py-5 px-4 px-sm-5">
              <div className="brand-logo">
                <img src={require("../../assets/images/logo.png")} alt="logo" />

                <h3>AESTHETIC ZONE INTERNATIONAL</h3>
              </div>
              <h4>Hello</h4>
              <h6 className="font-weight-light">Sign in to continue.</h6>
              <Form className="pt-3">
                <Form.Group className="d-flex search-field">
                  <Form.Control
                    type="email"
                    ref={usernameRef}
                    placeholder="Username"
                    size="lg"
                    className="h-auto"
                  />
                </Form.Group>
                <Form.Group className="d-flex search-field">
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    size="lg"
                    ref={passwordRef}
                    className="h-auto"
                  />
                </Form.Group>
                <div className="mt-3">
                  <Button
                    className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                    // to="/dashboard"
                    disabled={isLoading}
                    onClick={loginAdmin}
                  >
                    {isLoading ? "Please wait..." : "SIGN IN"}
                  </Button>
                </div>
                <div className="mt-3">
                  {error && <div className="alert alert-danger">{error}</div>}
                </div>

                {/* <div className="mb-2">
                  <button
                    type="button"
                    className="btn btn-block btn-facebook auth-form-btn"
                  >
                    <i className="mdi mdi-facebook mr-2"></i>Connect using
                    facebook
                  </button>
                </div> */}
                {/* <div className="text-center mt-4 font-weight-light">
                  Don't have an account?{" "}
                  <Link to="/user-pages/register" className="text-primary">
                    Create
                  </Link>
                </div> */}
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
