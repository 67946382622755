import api from "./api";
import TokenService from "./token.service";

const login = (username, password) => {
  return api
    .post("/v1/admins/auth/login", {
      username,
      password,
    })
    .then((response) => {
      if (response.status === 200) {
        TokenService.setUser(response.data);
      }

      return response.data;
    });
};

const changeUserPassword = (data) => {
  return api
    .post("/v1/admins/auth/change-password", {
      ...data,
    })
    .then((response) => {
      return response.data;
    });
};

const logout = () => {
  TokenService.removeUser();
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const AuthService = {
  login,
  logout,
  getCurrentUser,
  changeUserPassword,
};

export default AuthService;
