import React, { Suspense, lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import Spinner from "../app/shared/Spinner";
const AllNews = lazy(() => import("./news/AllNews.js"));
const AddNews = lazy(() => import("./news/AddNews.js"));
const EditNews = lazy(() => import("./news/EditNews.js"));

const AddPartner = lazy(() => import("./partners/AddPartner.js"));
const AllPartners = lazy(() => import("./partners/AllPartners.js"));
const EditPartner = lazy(() => import("./partners/EditPartner.js"));

const Dashboard = lazy(() => import("./dashboard/Dashboard"));

const Buttons = lazy(() => import("./basic-ui/Buttons"));
const Dropdowns = lazy(() => import("./basic-ui/Dropdowns"));
const Typography = lazy(() => import("./basic-ui/Typography"));

const BasicElements = lazy(() => import("./form-elements/BasicElements"));

const BasicTable = lazy(() => import("./tables/BasicTable"));

const Mdi = lazy(() => import("./icons/Mdi"));

const ChartJs = lazy(() => import("./charts/ChartJs"));

const Error404 = lazy(() => import("./error-pages/Error404"));
const Error500 = lazy(() => import("./error-pages/Error500"));

const Login = lazy(() => import("./user-pages/Login"));
const Register1 = lazy(() => import("./user-pages/Register"));
const Lockscreen = lazy(() => import("./user-pages/Lockscreen"));

const BlankPage = lazy(() => import("./general-pages/BlankPage"));
const AllRestaurants = lazy(() => import("./restaurants/AllRestaurants"));
const AddRestaurant = lazy(() => import("./restaurants/AddRestaurant"));
const EditRestaurant = lazy(() => import("./restaurants/EditRestaurant"));

const AllCategories = lazy(() => import("./categories/AllCategories"));
const AddCategory = lazy(() => import("./categories/AddCategory"));
const EditCategory = lazy(() => import("./categories/EditCategory"));

const AllMenus = lazy(() => import("./menus/AllMenus.js"));
const EditMenu = lazy(() => import("./menus/EditMenu.js"));
const EditAbout = lazy(() => import("./about/EditAbout.js"));

const AllProducts = lazy(() => import("./products/AllProducts"));
const AddProduct = lazy(() => import("./products/AddProduct"));
const EditProduct = lazy(() => import("./products/EditProduct"));

const EditContact = lazy(() => import("./contact/EditContact.js"));

const FirstTheme = lazy(() => import("./themes/FirstTheme.js"));

const AllSliders = lazy(() => import("./slider/AllSliders.js"));
const AddSlider = lazy(() => import("./slider/AddSlider.js"));
const EditSlider = lazy(() => import("./slider/EditSlider.js"));
const ChangePassword = lazy(() => import("./auth/ChangePassword.jsx"));
const AppRoutes = () => {
  return (
    <Suspense fallback={<Spinner />}>
      <Routes>
        <Route exact path="/dashboard" element={<Dashboard />} />

        <Route exact path="/about" element={<EditAbout />} />
        <Route
          exact
          path="/auth/change-password"
          element={<ChangePassword />}
        />

        <Route exact path="/contact" element={<EditContact />} />

        <Route exact path="/slider/all" element={<AllSliders />} />
        <Route exact path="/slider/add" element={<AddSlider />} />
        <Route exact path="/slider/edit/:id" element={<EditSlider />} />

        <Route exact path="/categories/all" element={<AllCategories />} />
        <Route exact path="/categories/add" element={<AddCategory />} />
        <Route exact path="/categories/edit/:id" element={<EditCategory />} />

        <Route exact path="/menus/all" element={<AllMenus />} />
        <Route exact path="/menus/edit/:id" element={<EditMenu />} />
        <Route exact path="/news/all" element={<AllNews />} />
        <Route exact path="/news/add" element={<AddNews />} />
        <Route exact path="/news/edit/:id" element={<EditNews />} />

        <Route exact path="/partners/all" element={<AllPartners />} />
        <Route exact path="/partners/add" element={<AddPartner />} />
        <Route exact path="/partners/edit/:id" element={<EditPartner />} />

        <Route exact path="/products/all" element={<AllProducts />} />

        <Route exact path="/products/add" element={<AddProduct />} />
        <Route exact path="/products/edit/:id" element={<EditProduct />} />

        <Route exact path="/themes" element={<FirstTheme />} />
        <Route path="/basic-ui/buttons" element={<Buttons />} />
        <Route path="/basic-ui/dropdowns" element={<Dropdowns />} />
        <Route path="/basic-ui/typography" element={<Typography />} />

        <Route
          path="/form-Elements/basic-elements"
          element={<BasicElements />}
        />

        <Route path="/tables/basic-table" element={<BasicTable />} />

        <Route path="/icons/mdi" element={<Mdi />} />

        <Route path="/charts/chart-js" element={<ChartJs />} />

        <Route path="/user-pages/login-1" element={<Login />} />
        <Route path="/user-pages/register-1" element={<Register1 />} />
        <Route path="/user-pages/lockscreen" element={<Lockscreen />} />

        <Route path="/error-pages/error-404" element={<Error404 />} />
        <Route path="/error-pages/error-500" element={<Error500 />} />

        <Route path="/general-pages/blank-page" element={BlankPage} />

        {/* <Route to="/dashboard" /> */}
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
