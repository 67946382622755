import React from "react";
// import ReactDOM from "react-dom";
import ReactDOM from "react-dom/client";

import { BrowserRouter } from "react-router-dom";
import App from "./app/App";
import "./i18n";
import * as serviceWorker from "./serviceWorker";
import store from "./store";
import { Provider } from "react-redux";
import "ckeditor5/ckeditor5.css";
// import "ckeditor5-premium-features/ckeditor5-premium-features.css";
import setupInterceptors from "../src/app/services/setupInterceptors";
// import 'dotenv/config';s
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <BrowserRouter basename="/">
      <App />
    </BrowserRouter>
  </Provider>
  // document.getElementById("root")
);
// );
setupInterceptors(store);
serviceWorker.unregister();
